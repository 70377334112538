import { Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-localstorage';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { MainBillingData } from 'src/app/connections/invoice/billingData';
import { PreRegisterUserInput } from 'src/app/connections/register/register';
import { getCurrentUser } from 'src/app/connections/user/queries';
import { UserAdmin } from 'src/app/connections/user/user';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SessiondataService {
  currentlanguage = '';
  public languageMessage;
  public currentUser;
  public billingData:MainBillingData
  registerNumber: PreRegisterUserInput;
  public user: UserAdmin;
  public token: any = '';

  private user$: BehaviorSubject<UserAdmin> = new BehaviorSubject<UserAdmin>(null);
  private billingData$: BehaviorSubject<MainBillingData> = new BehaviorSubject<MainBillingData>(null);

  constructor(
    private storage: LocalStorageService
  ) {
  }

  setData(key, data): Promise<boolean> {
    return new Promise(async (resolve) => {
      try {
        const response: any = await this.storage.set(key, data);
        resolve(true);
      } catch (e) {
        resolve(false);
      }
    })
  }

  getData(key) {
    return new Promise(async (resolve, reject) => {
      try {
        const response: any = await this.storage.get(key);
        resolve(response);
      } catch (e) {
        reject(e);
      }
    })
  }

  removeData(key): Promise<boolean> {
    return new Promise(async (resolve) => {
      try {
        const response: any = await this.storage.remove(key);
        resolve(true);
      } catch (e) {
        resolve(false);
      }
    })
  }

  clearAllData(): Promise<boolean> {
    return new Promise(async (resolve) => {
      try {
        const response: any = await this.storage.clear();
        resolve(true);
      } catch (e) {
        resolve(false);
      }
    })
  }

  userChange(): Observable<UserAdmin> {
    return this.user$.asObservable();
  }

  billingDataChange(): Observable<MainBillingData> {
    return this.billingData$.asObservable();
  }

  setUser(user: UserAdmin) {
    this.user = user;
    this.user$.next(this.user);
  }

  getUser() {
    return this.user;
  }


  setBillingData(billingData: MainBillingData) {
    this.billingData= billingData;
    this.billingData$.next(this.billingData);
  }

  getBillingData() {
    return this.billingData;
  }

  logout(): Promise<boolean> {
    this.user$.next(null);
    return this.clearAllData();
  }

  async getCurrentUser(): Promise<UserAdmin> {
    return new Promise(async (resolve, reject) => {
      // Busco los datos sin el servicio de graphql para evitar dependencia ciclica
      try {
        const token: any = await this.getData('token');
        if (token) {
          const query = getCurrentUser();
          let headers: any = { 'content-type': 'application/json', "Access-Control-Allow-Origin": "*" }
          headers = { ...headers, 'authorization': token }
          const response = await fetch(environment.uri, {
            headers: headers,
            method: 'POST',
            body: JSON.stringify({ query }),
          });
          const responseJson = await response.json();
          if (responseJson.data) {
            const { getCurrentUser } = responseJson.data;
            const { success, data }: any = getCurrentUser;
            if (success) {
              this.setUser(data);
              resolve(data);
            } else {
              this.logout();
              reject(null);
            }
          } else {
            this.logout();
            reject(null);
          }
        } else {
          reject(null);
        }
      } catch (e) {
        reject(null);
      }
    })
  }

  getToken() {
    return new Promise(async (resolve, reject) => {
      try {
        const response: any = await this.getData('token');
        resolve(response);
      } catch (e) {
        reject(null)
      }
    })
  }
}
