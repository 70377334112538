import {Component} from '@angular/core';
import {trigger, state, transition, style, animate} from '@angular/animations';
import { AuthService } from 'src/app/core/auth/auth.service';
import { SessiondataService } from 'src/app/core/services/sessiondata/sessiondata.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-inline-profile',
    templateUrl: 'app-profile.html',
    animations: [
        trigger('menu', [
            state('hidden', style({
                height: '0px'
            })),
            state('visible', style({
                height: '*'
            })),
            transition('visible => hidden', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
            transition('hidden => visible', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
        ])
    ]
})
export class AppProfileComponent {

    constructor(
        private dataSession: SessiondataService,
        private router: Router
    ){

    }

    active: boolean;

    onClick(event) {
        this.active = !this.active;
        event.preventDefault();
    }

    async logout(event) {
        event.preventDefault();
        let response = await this.dataSession.logout();
        if (response) {
            this.router.navigate(['/login']);
        }
    }
}
