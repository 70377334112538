import {RouterModule} from '@angular/router';
import {NgModule} from '@angular/core';

import {AppMainComponent} from './app.main.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { AuthGuard } from './core/auth/guards/auth.guard';
import { AppLoginComponent } from './pages/login/app.login.component';
import { AppErrorComponent } from './pages/app-error/app.error.component';
import { AppNotfoundComponent } from './pages/app-notfound/app.notfound.component';
import { AppAccessdeniedComponent } from './pages/app-accessdenied/app.accessdenied.component';

@NgModule({
    imports: [
        RouterModule.forRoot([
            {
                path: '', component: AppMainComponent,
                children: [
                    //{path: '', component: DashboardComponent, canActivate: [AuthGuard]},
                    {
                        path: 'users',
                        loadChildren: () => import('./modules/users/users.module').then(m => m.UsersModule),
                        canActivate: [AuthGuard]
                        // data: {
                        //   role: ['Admin', 'superadmin']
                        // },
                      },
                      {
                        path: 'invoices',
                        loadChildren: () => import('./modules/invoices/invoices.module').then(m => m.InvoicesModule),
                        canActivate: [AuthGuard]
                        // data: {
                        //   role: ['Admin', 'superadmin']
                        // },
                      },
                ]
            },

            

            {path: 'error', component: AppErrorComponent},
            {path: 'accessdenied', component: AppAccessdeniedComponent},
            {path: 'notfound', component: AppNotfoundComponent},
            {path: 'login', component: AppLoginComponent},
            {path: '**', redirectTo: '/notfound'},
        ], {scrollPositionRestoration: 'enabled'})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
