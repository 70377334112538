import { Component } from '@angular/core';

@Component({
    selector: 'app-footer',
    template: `
        <div class="layout-footer">
            <span class="footer-text-left">
                <img src="assets/layout/images/icon.png" />
            </span>
            <span class="footer-text-right">
                Facturamas 2021
            </span>
        </div>
    `
})
export class AppFooterComponent {

}
