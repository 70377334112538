import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { UserAdmin } from 'src/app/connections/user/user';
import { SessiondataService } from '../services/sessiondata/sessiondata.service';

@Injectable()
export class InitUserService {
    constructor(
        private dataSession: SessiondataService
    ) { }

    initCurrentUser(): Promise<UserAdmin> {
        return this.dataSession.getCurrentUser();
    }
}
