import { signInInput } from "./signIn";

// export function signInUser(user: signInInput){
//     return `mutation {
//         signIn(signInInput : {
//             username: "${user.username}"
//             password: "${user.password}"
//         }){
//             message
//             code
//             data{
//                 name
//                 lastName
//                 identification
//                 dteBirthday
//                 identificationType
//                 username
//                 email
//                 gender
//                 phone{
//                     e164
//                     nationalNumber
//                     countryCode
//                 }
//                 useDataOnATV
//                 appVersion
//                 profileIsCompleted
//                 userStatus
//                 codConfirmation{
//                     expire
//                     code
//                 }
//                 termsAccepted
//                 dteLastSignIn
//                 profilePicture
//                 inboxMail
//                 }
//                 token
//             }
//         }`
// }

export function signInAdmin(user: signInInput){
    return `mutation {
        adminSignIn(adminSignInData:{
            username: "${user.username}"
            password: "${user.password}"
        }){
            data{
                identification
                role
                email
                name
                lastName
                userStatus
                dteLastSignIn
                createdAt
            }
            token
            success
            message
        }
        }`
}