import { CreateUserInput } from "./create";
import { UpdateUserInput } from "./user";


export function updateUser(user: UpdateUserInput) {
    
    const {
        name,
        lastName,
        identification,
        email,
        dteBirthday,
        phone,
        useDataOnATV,
        appVersion,
        profileIsCompleted,
        termsAccepted,
        profilePicture,
        gender
    } = user;

    return `mutation {
        updateUser(updateUserData  : {
            name: "${name}"
            lastName: "${lastName}"
            identification: "${identification}"
            email: "${email}"
            dteBirthday: "${dteBirthday}"
            phone: {
                e164: "${phone.e164}"
                nationalNumber: "${phone.nationalNumber}"
                countryCode: "${phone.countryCode}"
            }
            useDataOnATV: ${useDataOnATV},
            appVersion: "${appVersion}"
            profileIsCompleted: ${profileIsCompleted}
            termsAccepted: ${termsAccepted}
            ${profilePicture ? 'profilePicture: "' + profilePicture + '"' : ''}
            gender: ${gender}
        }){
            success
            message
            code
            data{
                name
                lastName
                identification
                dteBirthday
                identificationType
                password
                username
                email
                phone{
                    e164
                    nationalNumber
                    countryCode
                }
                useDataOnATV
                appVersion
                profileIsCompleted
                userStatus
                codConfirmation{
                    expire
                    code
                }
                termsAccepted
                dteLastSignIn
                profilePicture
                inboxMail
                gender
                }
            }
        }`
}

export function changeUserStatus(userId: string,status:string) {

    return `mutation{
        changeUserStatus(changeUserStatusData:{
          userId:"${userId}"
          status: ${status}
        }){
              message
          success
        }
      }`

}

export function createUserAdmin(user: CreateUserInput) {

    const {
        name,
        lastName,
        password,
        identification,
        email,
        dteBirthday,
        phone,
        useDataOnATV,
        appVersion,
        profilePicture,
        gender
    } = user;

    return `mutation {
        createUserByAdmin(createUserData : {
            name: "${name}"
            lastName: "${lastName}"
            fullName: "${name}"
            password: "${password}"
            lang:"esp"
            identification: "${identification}"
            identificationType:""
            email: "${email}"
            dteBirthday: "${dteBirthday}"
            phone: {
                e164: "${phone.e164}"
                nationalNumber: "${phone.nationalNumber}"
                countryCode: "${phone.countryCode}"
            }
            useDataOnATV: ${useDataOnATV ? useDataOnATV : false},
            profilePicture: "${profilePicture}"
            gender: "${gender}"
        }){
            success
            message
            code
            data{
                name
                lastName
                identification
                dteBirthday
                identificationType
                email
                phone{
                    e164
                    nationalNumber
                    countryCode
                }
                useDataOnATV
                termsAccepted
                profilePicture
                gender
                }
            }
        }`
}

export function getAllUsers() {
    return `
        query{
            getAllUsers{
                message
                success
                data{
                    _id
                    name
                    lastName
                    email
                    userStatus
                    termsAccepted
                    identificationType
                    identification
                }
            }
        }
    `
}

export function getUserById(id: string) {
    return `
        query{
            getUserById(getUserIdData:{
                _id: "${id}"
            }){
                message
                success
                data {
                    user {
                      _id
                      name
                      lastName
                      email
                      dteLastSignIn
                      identification
                      userStatus
                      dteBirthday
                      phone {
                        e164
                        nationalNumber
                        countryCode
                      }
                      gender
                      useDataOnATV
                      profilePicture
                    }
                    business {
                      _id
                      identification
                      identificationType
                      businessName
                      tradeName
                      email
                      phone
                      image
                      useThisData
                    }
                    billing {
                      _id
                      identificationType
                      identification
                              email
                      fullName
                      ATVuser
                      ATVpassword
                      certFileATV
                      pintATV
                      activities {
                        state
                        type
                        code
                        description
                      }
                      address{
                        province{
                          code
                          name
                        }
                        canton{
                          code
                          name
                        }
                        district{
                          code
                          name
                        }
                        neighborhood{
                          code
                          name
                        }
                        exactAddress
                      }
                      consecutives {
                        exportInvoice
                        purchaseInvoice
                        debitNote
                        creditNote
                        electronicTicket
                        electronicInvoice
                      }
                    }
                }
            }
        }
    `
}

export function getCurrentUser() {
    return `
        query{
            getCurrentUser {
                message
                success
                data{
                    name
                    lastName
                    identification
                    _id
                    role
                }
            }
        }
    `
}