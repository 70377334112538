import { Injectable } from '@angular/core';
import { SessiondataService } from '../services/sessiondata/sessiondata.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private dataSession: SessiondataService
  ) { }

  check() {
    let token = this.dataSession.getToken();
    return token ? token : false;
  }
}
