import { GetBillingByAdminInput } from "../../billing/input"

export function getMainBackendData() {
    return `query {
        getPaymentMethods {
            success
            message
            description
            data{
                name
                code
            }
        }
        getSalesConditions{
            success
            message
            description
            data{
                name
                code
            }
        }
        getEproofTypes{
            success
            message
            description
            data{
                name
                code
            }
        }
        getTaxRates{
          description
          code
          success
          data{
            code
          name
            rate
          }
        }
        getTaxCodes{
          description
          code
          success
          data{
            code
            name
          }
        }
        getExonerationDocTypes{
          description
          success
          code
          data{
          code
            name
          }
        }
        getReferenceDocTypes{
          description
          success
          code
          data{
          code
            name
          }
        }
        getDollarCurrencyExchange{
            success
            message
            description
            data{
              venta{
                fecha
                valor
              }
              compra{
                fecha
                valor
              }
            }
        }

        getServiceUnits{
            description
            success
            data{
              symbol
              description
          }
        }

        getProductUnits{
              description
              success
              data{
                symbol
                description
            }
        }


    }`
}

export function getAllBillingUsers() {
  return `
      query{
          getAllBillingUsers{
              message
              success
              data{
                  _id
                  identification,
                  identificationType, 
                  isValid,
                  email,
                  fullName,
              }
          }
      }
  `
}

export function getBillingUserByAdmin(data:GetBillingByAdminInput) {
  return `
      query{
        getBillingByAdmin(getBillingData:{
          _id: "${data._id}"
        }){
              message
              success
              data{
                _id
                IDUser
                ATVuser
                ATVpassword
                certFileATV
                pintATV
                fullName
                identification
                identificationType
                billingTaxRegime {
                    description
                    code
                }
                consecutives{
                   purchaseInvoice
                   electronicInvoice
                   electronicTicket
                   creditNote
                   debitNote
                  exportInvoice
                }
                address{
                    province{
                      code
                      name
                    }
                    canton{
                      code
                      name
                    }
                    district{
                      code
                      name
                    }
                    neighborhood{
                      code
                      name
                    }
                    exactAddress
                }
                email
                forwardEmails
                inboxMail
                activities{
                    state
                    type
                    code
                    description
              }
            }
          }
      }
  `
}

export function getClientsByAdmin(data:GetBillingByAdminInput) {
  return `
      query{
        getClientsByAdmin(getClientsData:{
          _id: "${data._id}"
        }){
              message
              success
              data{
                _id
                IDUser
                landLinePhone{
                    e164
                    nationalNumber
                    countryCode
                }
                movilePhone{
                    e164
                    nationalNumber
                    countryCode
                }
                email
                clientType
                address{
                    province{
                      code
                      name
                    }
                  canton{
                      code
                      name
                    }
                    district{
                      code
                      name
                    }
                    district{
                      code
                      name                      
                    }
                  neighborhood{
                    code
                    name
                  }
                    exactAddress
                }
                name
                lastName
                identificationType
                identification
                businessName
                tradeName
                societyType
                profilePicture
                exonet {
                  docNumber
                  exonerationPercentage
                  expireDate
                  cabysCodes
                  emisionDate
                }
              }
            }
          
      }
  `
}