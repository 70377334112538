import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Message, MessageService } from 'primeng/api';
import { GraphqlConnectionService } from 'src/app/core/services/graphql-connection/graphql-connection.service';
import { SessiondataService } from 'src/app/core/services/sessiondata/sessiondata.service';
import { signInAdmin } from 'src/app/connections/signIn/queries';
import { SignInOutput } from 'src/app/connections/signIn/signIn';
import { UserAdmin } from 'src/app/connections/user/user';
import { getMainBackendData } from 'src/app/connections/invoice/queries';
import { MainBillingData } from 'src/app/connections/invoice/billingData';

@Component({
  selector: 'app-login',
  templateUrl: './app.login.component.html',
  providers: [MessageService]

})
export class AppLoginComponent implements OnInit {

  form: FormGroup;
  showMessage: Message[] = null;

  constructor(
    private formBuilder: FormBuilder,
    private sessiondata: SessiondataService,
    private connection: GraphqlConnectionService,
    private router: Router,
    private service: MessageService,
    private spinner: NgxSpinnerService
  ) {
  }

  ngOnInit(): void {
    // Create the form
    this.form = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  async getBillingData():Promise<MainBillingData> {
    this.spinner.show();
    try {
      const mutation = getMainBackendData()
      const response = await this.connection.post(mutation);

      if(!response.data.getPaymentMethods)
      throw "Payment Method not Found";

      if(!response.data.getSalesConditions)
        throw "Sale Condition Method not Found";

      if(!response.data.getTaxRates)
        throw "TaxRate not Found";

      if(!response.data.getTaxCodes)
        throw "Tax Codes Method not Found";

      if(!response.data.getProductUnits)
        throw "Product Measure Units not Found";
      
      if(!response.data.getServiceUnits)
        throw "Service Measure Units not Found";

      if(!response.data.getReferenceDocTypes)
        throw "Reference Doc Types Units not Found";

      this.spinner.hide();
      if (response && response.data) {
        const data:MainBillingData = {
          paymentMethods:response.data.getPaymentMethods.data,
          taxCodes: response.data.getTaxCodes.data,
          taxRates: response.data.getTaxRates.data,
          serviceMeasureUnits: response.data.getServiceUnits.data,
          productMeasureUnits: response.data.getProductUnits.data,
          salesConditions: response.data.getSalesConditions.data,
          referenceDocTypes: response.data.getReferenceDocTypes
        }
        if (data) {
          const billingData: MainBillingData= data;
          this.sessiondata.setBillingData(billingData)  
          return billingData;
        } else {
          // this.incorrectCredentials()
          this.showError();
        }
      } else {
        this.showError();        // this.incorrectCredentials()
      }
    } catch {
      this.spinner.hide();
      this.showError();
    }
  }

  async login() {
    this.spinner.show();
    try {
      const mutation = signInAdmin(this.form.value);
      const response = await this.connection.post(mutation);
      this.spinner.hide();
      if (response && response.data) {
        const { adminSignIn }: any = response.data;
        const { data, token }: SignInOutput = adminSignIn;
        const billingData = await this.getBillingData();
        if (data) {
          const savedUser: UserAdmin = data;
          this.sessiondata.setBillingData(billingData);
          this.sessiondata.setUser(savedUser);
          this.sessiondata.setData('token', token);   
          this.sessiondata.setData('billingData', billingData);    

          const saved: any = await this.sessiondata.setData('isLoggedIn', {saved: true, ...savedUser});
          if(saved){
            this.router.navigate(['']);   
          }
        } else {
          // this.incorrectCredentials()
          this.showError();
        }
      } else {
        this.showError();        // this.incorrectCredentials()
      }
    } catch(e) {
      console.log(e)
      this.spinner.hide();
      this.showError();
    }
  }

  showError() {
    this.service.add({
      key: 'error-toast',
      severity: 'error',
      summary: 'Credenciales incorrectas',
      detail: 'Revise los datos ingresados e intente nuevamente'
    });
  }

}
