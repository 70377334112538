import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { takeWhile } from 'rxjs/operators';
import { UserAdmin } from 'src/app/connections/user/user';
import { SessiondataService } from 'src/app/core/services/sessiondata/sessiondata.service';
import { AppComponent } from '../../app.component';
import { AppMainComponent } from '../../app.main.component';

@Component({
    selector: 'app-topbar',
    templateUrl: 'app-topbar.html',
})
export class AppTopBarComponent implements OnInit, OnDestroy {

    alive: boolean = true;
    user: UserAdmin;

    constructor(
        public app: AppComponent,
        public appMain: AppMainComponent,
        private dataSession: SessiondataService,
        private router: Router
    ) { }

    ngOnInit(): void {
        this.dataSession.userChange()
            .pipe(takeWhile(() => this.alive))
            .subscribe(
                (user) => {
                    this.user = user;
                }
            );
    }

    ngOnDestroy(): void {
        this.alive = false;
    }

    async logout(event) {
        event.preventDefault();
        let response = await this.dataSession.logout();
        if (response) {
            this.router.navigate(['/login']);
        }
    }

}
